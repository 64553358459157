import React from 'react';
import ConnectWithLivingAtlas from './ConnectWithLivingAtlas';
import JoinConversation from './JoinConversation';

const SiteFooter = () => {
    return (
        <>
            <JoinConversation />
            <ConnectWithLivingAtlas />
        </>
    );
};

export default SiteFooter;
