import './ConnectWithLivingAtlas.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    GEONET_LIVING_ATLAS_URL,
    TWITTER_LIVING_ATLAS_URL,
} from '@shared/constants/urls';

const socialIconClassNames = `mx-1 border border-transparent hover:border-white tooltip tooltip-top`;

const ConnectWithLivingAtlas = () => {
    const { t } = useTranslation();

    return (
        <div className="w-full bg-calcite-background-black text-white py-10 text-center">
            <p className="font-size-2 font-light mb-4">
                {t('connect_with_living_atlas', { ns: 'common' })}
            </p>
            <a
                className={`icon-social-sdk-home icon-social-twitter ${socialIconClassNames}`}
                aria-label={t('connect_on_twitter', { ns: 'common' })}
                href={TWITTER_LIVING_ATLAS_URL}
                target={'_blank'}
                rel="noreferrer"
            ></a>
            <a
                className={`icon-social-sdk-home icon-social-geonet ${socialIconClassNames}`}
                aria-label={t('join_community', { ns: 'common' })}
                href={GEONET_LIVING_ATLAS_URL}
                target={'_blank'}
                rel="noreferrer"
            ></a>
        </div>
    );
};

export default ConnectWithLivingAtlas;
