import React from 'react';

const PageLoadingIndicator = () => {
    return (
        <div className="my-32 flex justify-center items-center">
            <calcite-loader active></calcite-loader>
        </div>
    );
};

export default PageLoadingIndicator;
