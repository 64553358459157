import React from 'react';
import LivingAtlasIcon from './LivingAtlasIcon.png';
import SiteNavBtns from './SiteNavBtns';

type Props = {
    /**
     * title of the Site: `ArcGIS Living Atlas of the World`
     */
    title: string;
};

/**
 * Size of the Living Atlas Brand Icon
 */
const IconSize = 40;

const regionNameContainerClassName = `region-name-in-site-nav`;

/**
 * Call this function to show selected region (e.g. "United States Edition") from the Browse Page in the Site Nav.
 * Was thinking about handling this using a Site Wide context but figured it would be unnecessary since this is the only
 * site wide component that need to be updated by components inside of the pages.
 * @param regionName
 */
export const updateRegionNameInSiteNav = (regionName: string) => {
    const elem = document.querySelector(`.${regionNameContainerClassName}`);

    if (!elem) {
        return;
    }

    elem.innerHTML = regionName
        ? `
        <div class='p-2 px-3 hidden md:block bg-calcite-brand-blue text-white ml-4'>
            ${regionName}
        </div>
    `
        : '';
};

const SiteNav: React.FC<Props> = ({ title }: Props) => {
    return (
        <div className="h-site-nav w-full bg-calcite-background-black">
            <div className="container h-full flex items-center justify-between">
                <div className="text-white flex items-center">
                    <img
                        className="hidden md:block mr-2"
                        src={LivingAtlasIcon}
                        height={IconSize}
                        width={IconSize}
                    />
                    <span className="text-xl font-light">{title}</span>

                    <div className={regionNameContainerClassName}></div>
                </div>

                <SiteNavBtns />
            </div>
        </div>
    );
};

export default SiteNav;
