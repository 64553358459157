import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageSelectorOption } from './LanguageSelectorContainer';

type Props = {
    /**
     * data to populate language selector list
     */
    data: LanguageSelectorOption[];
    /**
     * Fires when user selects a new language
     */
    onChange: (language: string) => void;
    /**
     * Firesw when user clicks on "Cancel" button
     */
    closeBtnOnClick: () => void;
};

const LanguageSelector: FC<Props> = ({ data, onChange, closeBtnOnClick }) => {
    const { t } = useTranslation();

    const calciteSelectRef = useRef<any>();

    // useEffect(()=>{
    //     calciteSelectRef.current.addEventListener('calciteSelectChange', ()=>{
    //         console.log(calciteSelectRef.current.value)
    //     })
    // }, [])

    return (
        <calcite-modal
            aria-labelledby="modal-title"
            id="example-modal"
            open
            close-button-disabled
            outside-close-disabled
            scale="s"
        >
            <div slot="header" id="modal-title">
                {t('GLOBAL_NAV_FOOTER_LANG_SEL_HDR', { ns: 'global-nav' })}
            </div>

            <div slot="content">
                <calcite-select ref={calciteSelectRef}>
                    {data.map((d) => {
                        return (
                            <calcite-option key={d.language} value={d.language}>
                                {d.label}
                            </calcite-option>
                        );
                    })}
                </calcite-select>
            </div>

            <calcite-button
                slot="secondary"
                width="full"
                appearance="outline"
                onClick={closeBtnOnClick}
            >
                {t('cancel-btn-label', { ns: 'common' })}
            </calcite-button>

            <calcite-button
                slot="primary"
                width="full"
                onClick={() => {
                    onChange(calciteSelectRef.current.value);
                }}
            >
                {t('ok-btn-label', { ns: 'common' })}
            </calcite-button>
        </calcite-modal>
    );
};

export default LanguageSelector;
