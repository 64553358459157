import React, { FC, useEffect, useRef, useState } from 'react';
import { PREFERRED_LANG_SEARCH_PARAM_KEY } from '@shared/utils/redirect/redirect2PreferredLanguage';
import { parseUrlPathname } from '@shared/utils/url/path';
import LanguageSelector from './LanguageSelector';

export type LanguageSelectorOption = {
    language: string;
    label: string;
};

const LanguageSelectorOptions: LanguageSelectorOption[] = [
    {
        language: 'en',
        label: 'English (Global)',
    },
    {
        language: 'es',
        label: 'Español (Spanish)',
    },
    {
        language: 'de',
        label: 'Deutsch (German)',
    },
    {
        language: 'fr',
        label: 'Français (French)',
    },
    {
        language: 'ja',
        label: '日本語 (Japanese)',
    },
    {
        language: 'pt-br',
        label: 'Português (Brasil)',
    },
];

const { language } = parseUrlPathname();

const LanguageSelectorContainer: FC = () => {
    const languageButtonContainer = useRef<HTMLDivElement>();

    const [showLanguageSelector, setShowLanguageSelector] = useState(false);

    /**
     * initialize language selector butto that will be place at left bottom corner inside of the Esri Footer
     */
    const initLanguagueButton = () => {
        languageButtonContainer.current = document.querySelector(
            '.esri-footer-language'
        );

        if (!languageButtonContainer.current) {
            return;
        }

        // get label for language of the current page
        const languageOptionData4CurrentPage = LanguageSelectorOptions.find(
            (d) => d.language === language
        );

        languageButtonContainer.current.innerHTML = `
            <calcite-button scale="l" icon-end="globe">${
                languageOptionData4CurrentPage?.label || 'English'
            }</calcite-button>
        `;

        languageButtonContainer.current.addEventListener('click', () => {
            setShowLanguageSelector(true);
        });
    };

    useEffect(() => {
        initLanguagueButton();
    }, []);

    if (!showLanguageSelector) {
        return null;
    }

    return (
        <LanguageSelector
            data={LanguageSelectorOptions}
            onChange={(selectedLanguage: string) => {
                // abort if selected language is same to language of current page
                if (selectedLanguage === language) {
                    return;
                }

                // reload the page with selected language is URL search params
                window.location.search = `${PREFERRED_LANG_SEARCH_PARAM_KEY}=${selectedLanguage}`;
            }}
            closeBtnOnClick={setShowLanguageSelector.bind(null, false)}
        />
    );
};

export default LanguageSelectorContainer;
