/**
 * App ID required by Esri OAuth
 */
export const DEV_APP_ID = 'LAWwebsite';
export const PRD_APP_ID = 'LAWwebsite';

/**
 * ArcGIS Online Domain
 */
export const DEV_PORTAL_DOMAIN = 'https://devext.arcgis.com';
export const PROD_PORTAL_DOMAIN = 'https://www.arcgis.com';

/**
 * Storymaps Domain
 */
export const DEV_STORYMAPS_ROOT = 'https://storymapsdev.arcgis.com';
export const PROD_STORYMAPS_ROOT = 'https://storymaps.arcgis.com';

/**
 * Experience Domain
 */
export const DEV_EXPERIENCE_ROOT = 'https://experiencedev.arcgis.com';
export const PROD_EXPERIENCE_ROOT = 'https://experience.arcgis.com';

/**
 * Id of the Living Atlas Master Group
 */
export const DEV_MASTER_GROUP_ID = '6a227743f5404850803716f4bb79e787';
export const PROD_MASTER_GROUP_ID = '47dd57c9a59d458c86d3d6b978560088';

/**
 * Items Id of the Group Schema JSON file for Living Atlas Master Group
 */
export const DEV_SCHEMA_ID = '2e2012d735b540d7bd99b31272b3d815';
export const PRD_SCHEMA_ID = '1ad6b64fe4e1428a8f182dd6010fc2c9';

/**
 * Content Nomination Table used to store User Contributions to the Living Atlas.
 */
export const DEV_CONTENT_NOMINATION_TABLE =
    'https://servicesdev.arcgis.com/VLx4vrvwONglS8iz/arcgis/rest/services/Content_Nomination/FeatureServer/0';
export const PROD_CONTENT_NOMINATION_TABLE =
    'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/Content_Nomination/FeatureServer/0';
