import React from 'react';
import { createRoot } from 'react-dom/client';

import SiteLayout from '@shared/components/SiteLayout/SiteLayout';

import { initI18next } from '@shared/utils/i18n';
import { redirect2BrowsePage } from '@shared/utils/redirect/redirect2BrowsePage';
import { redirect2PreferredLanguage } from '@shared/utils/redirect/redirect2PreferredLanguage';

(async () => {
    redirect2PreferredLanguage();

    // Esri Gloabl Nav search would add the search term to window.location.search using `q` as the key,
    // Therefore we need redirect the user to the browse page if `q` is found in URL search param
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('q')) {
        redirect2BrowsePage(urlSearchParams.get('q'));
    }

    await initI18next();

    const root = createRoot(document.getElementById('root'));

    root.render(<SiteLayout />);
})();
