import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextLocalesLoader from 'i18next-http-backend';
import { parseUrlPathname } from '../url/path';
import { format } from 'date-fns';

/**
 * load the locale files from `/public/locales/` for the current page
 * and initiate i18next library
 *
 * @returns void
 */
export const initI18next = async () => {
    // get the "last-modified" meta tag, which gets added to 'index.html' file during the build process by webpack
    const lastModifiedMetaTag = document.querySelector(
        'meta[name="last-modified"]'
    );

    // get the content from 'last-modified' meta tag or use a placeholder value in case the "last-modified" meta tag is not found.
    const lastModifiedTimestamp =
        lastModifiedMetaTag?.getAttribute('content') ||
        format(new Date(), 'yyyy-MM-dd');

    const i18nextLocalesLoader = new I18nextLocalesLoader(null, {
        loadPath: `/public/locales/{{lng}}/{{ns}}.json?modified=${lastModifiedTimestamp}`,
    });

    const { language, page } = parseUrlPathname();

    await i18next
        .use(i18nextLocalesLoader)
        .use(initReactI18next)
        .init({
            lng: language,
            /**
             * set this to true so that locale will be fully lowercased,
             * otherwise it will convert "pt-br" to "pt-BR" which can cause issue
             * when load the locale files
             */
            lowerCaseLng: true,
            load: 'currentOnly',
            fallbackLng: 'en',
            /**
             * each of these namespaces below will be mapped to a file in the the `/public/locales/{{lang}}` folder.
             *
             * each page will have three namespaces or locale files:
             *
             * - the locale file for current page (e.g. "home.json" , "apps.json") that contains strings for the current page that are not used by any other page
             * - "common.json" locale file that contains strings shared by different pages
             * - "global-nav.json" locale file that contains strings that will be used to populate the Esri global nav
             */
            ns: [page, 'common', 'global-nav'],
            /**
             * the default namespace or locale file that will be used by i18next will be
             * the locale file for current page (e.g. "home.json" , "apps.json")
             *
             * The default namespace will be used when using i18next in a React Component without specifying a namespace
             * ```
             * const MyComponent = ()=>{
             *   const { t } = useTranslation()
             *
             *   // to get translated string from the default namespace
             *   t("hello-world")
             *
             *   // to get translated string from a specific namespace (e.g "common")
             *   t("welcome-message", { ns: "common" })
             * }
             *
             * ```
             */
            defaultNS: page,
        });
};
