import '@shared/styles/index.css';

import React, { lazy, Suspense, FC, useEffect, useState } from 'react';

import { EsriGlobalNav, SiteNav } from '..';
import { parseUrlPathname } from '@shared/utils/url/path';
import SiteFooter from '../SiteFooter/SiteFooter';
import LanguageSelector from '../LanguagueSelector/LanguageSelectorContainer';
import { initEsriOAuth } from '@shared/utils/esri-oauth';
import { APP_ID, PORTAL_URL } from '@shared/utils/environment-variables';
import PageLoadingIndicator from './PageLoadingIndicator';

const Home = lazy(
    () =>
        import(
            /* webpackChunkName: "Home" */
            '@home/Home'
        )
);
const Browse = lazy(
    () =>
        import(
            /* webpackChunkName: "Browse" */
            '@browse/Browse'
        )
);

const Apps = lazy(
    () =>
        import(
            /* webpackChunkName: "Apps" */
            '@apps/Apps'
        )
);

const Contribute = lazy(
    () =>
        import(
            /* webpackChunkName: "Contribute" */
            '@contribute/Contribute'
        )
);

const MyFav = lazy(
    () =>
        import(
            /* webpackChunkName: "MyFav" */
            '@myfav/MyFav'
        )
);

const Resources = lazy(
    () =>
        import(
            /* webpackChunkName: "Resources" */
            '@resources/Resources'
        )
);

const { page } = parseUrlPathname();

/**
 * Entrypoint of the Living Atlas Site
 *
 * This component manages the site wide layout (Esri Nav, Site Nav, Site Footer) and lazy load the content for each page.
 *
 */
const SiteLayout: FC = () => {
    /**
     * If true, the async task of checking the sign-in status of the current user is done
     */
    const [signInStatusIsChecked, setSignInStatusIsChecked] = useState(false);

    useEffect(() => {
        (async () => {
            await initEsriOAuth({
                appId: APP_ID,
                portalUrl: PORTAL_URL,
            });

            setSignInStatusIsChecked(true);
        })();
    }, []);

    return (
        <>
            <EsriGlobalNav signInStatusIsChecked={signInStatusIsChecked} />
            <SiteNav title={'ArcGIS Living Atlas of the World'} />
            <Suspense fallback={<PageLoadingIndicator />}>
                {page === 'home' && <Home />}
                {page === 'apps' && <Apps />}
                {page === 'resources' && <Resources />}
                {page === 'browse' &&
                    (signInStatusIsChecked ? (
                        <Browse />
                    ) : (
                        <PageLoadingIndicator />
                    ))}
                {page === 'contributions' &&
                    (signInStatusIsChecked ? (
                        <Contribute />
                    ) : (
                        <PageLoadingIndicator />
                    ))}
                {page === 'myfav' &&
                    (signInStatusIsChecked ? (
                        <MyFav />
                    ) : (
                        <PageLoadingIndicator />
                    ))}
            </Suspense>
            <SiteFooter />
            {signInStatusIsChecked && <LanguageSelector />}
        </>
    );
};

export default SiteLayout;
