import Cookies from 'js-cookie';
import SiteConfig from '../../../../site.config.json';
import { parseUrlPathname } from '../url/path';
const { LANGUAGES } = SiteConfig;

const { language, page } = parseUrlPathname();

const PREFERRED_LANG_COOKIE_KEY = 'preflang';

/**
 * the Esri Global Nav would add user selected language
 * to URL search params using `lang` as the key
 */
export const PREFERRED_LANG_SEARCH_PARAM_KEY = 'lang';

const isLanguageSupportedByLivingAtlas = (lang = '') => {
    return LANGUAGES.includes(lang);
};

/**
 * Get user preferred language from cookie
 * @returns
 */
const getPreferredLocale = () => {
    const prefLang = Cookies.get(PREFERRED_LANG_COOKIE_KEY);
    return prefLang;
};

/**
 * Save user preferred language to cookie
 * @param lang
 * @returns
 */
const setPreferredLang = (lang = '') => {
    // abort if preferred language is not supported by living atlas
    if (!isLanguageSupportedByLivingAtlas(lang)) {
        return;
    }

    // no need to save en as preferred language is the input language is English
    lang = lang !== 'en' ? lang : '';

    Cookies.set(PREFERRED_LANG_COOKIE_KEY, lang, {
        path: '/',
        domain: '.arcgis.com',
    });
};

const goToPageInSelectedLanguage = (locale = '') => {
    // selected language is not supported by living atlas
    // use English instead
    if (isLanguageSupportedByLivingAtlas(locale) === false) {
        locale = 'en';
    }

    // pathname using user preferred language
    const pathname = `/${locale}/${page}/`;

    const url = window.location.origin + pathname + window.location.hash;

    window.location.replace(url);
};

/**
 * redirect user to the page of their preferred language.
 */
export const redirect2PreferredLanguage = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    // language of the current page
    const currentLocale = language;

    // preferred language retrieved from cookie
    const preferredLocale = getPreferredLocale();

    // User selected language retrieved from the the URL search params.
    // Esri global nav would add selected language to URL Search Params
    // after making the selection
    const localFromSearchParam = urlSearchParams.get(
        PREFERRED_LANG_SEARCH_PARAM_KEY
    );

    if (localFromSearchParam) {
        if (currentLocale !== localFromSearchParam) {
            setPreferredLang(localFromSearchParam);
        }
        goToPageInSelectedLanguage(localFromSearchParam);
    } else if (preferredLocale && preferredLocale !== currentLocale) {
        goToPageInSelectedLanguage(preferredLocale);
    }
};
