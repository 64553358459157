import React, { useEffect, useContext } from 'react';

import { initEsriGlobalNav } from '@shared/utils/esri-global-nav';

type Props = {
    /**
     * If true, the async task of checking the sign-in status of the current user is done
     */
    signInStatusIsChecked: boolean;
};

const EsriGlobalNav: React.FC<Props> = ({ signInStatusIsChecked }) => {
    useEffect(() => {
        initEsriGlobalNav(signInStatusIsChecked);
    }, [signInStatusIsChecked]);

    return null;
};

export default EsriGlobalNav;
