import {
    DEV_APP_ID,
    PRD_APP_ID,
    DEV_PORTAL_DOMAIN,
    PROD_PORTAL_DOMAIN,
    DEV_MASTER_GROUP_ID,
    PROD_MASTER_GROUP_ID,
    DEV_SCHEMA_ID,
    PRD_SCHEMA_ID,
    DEV_CONTENT_NOMINATION_TABLE,
    PROD_CONTENT_NOMINATION_TABLE,
    DEV_STORYMAPS_ROOT,
    PROD_STORYMAPS_ROOT,
    PROD_EXPERIENCE_ROOT,
    DEV_EXPERIENCE_ROOT,
} from './constants';

const urlHashParams = new URLSearchParams(window.location.hash.slice(1));

/**
 * If true, use environment variables for PRODUCTION tier.
 *
 * To mock PROD environment in local dev environment, add `env=prod` to URL Hash Params:
 *
 * https://localhost:8080/en/browse/#env=prod
 */
const isPROD =
    window.location.hostname === `livingatlas.arcgis.com` ||
    (NODE_ENV === 'development' && urlHashParams.get('env') === 'prod');

export const APP_ID = isPROD ? PRD_APP_ID : DEV_APP_ID;

/**
 * Id of Living Atlas Master Group
 */
export const LIVING_ATLAS_MASTER_GROUP_ID = isPROD
    ? PROD_MASTER_GROUP_ID
    : DEV_MASTER_GROUP_ID;

/**
 * Items Id of the Group Schema JSON file for Living Atlas Master Group
 */
export const MASTER_GROUP_SCHEMA_ID = isPROD ? PRD_SCHEMA_ID : DEV_SCHEMA_ID;

/**
 * Host name of ArcGIS Online Portal
 */
export const PORTAL_URL = isPROD ? PROD_PORTAL_DOMAIN : DEV_PORTAL_DOMAIN;

export const SHARING_HOST_URL = PORTAL_URL + '/sharing';

/**
 * ArcGIS Online request URL used to access the self resource
 *
 * @example
 * ```
 * https://www.arcgis.com/sharing/rest/portals/self
 * ```
 */
export const PORTAL_SELF_URL = `${PORTAL_URL}/sharing/rest/portals/self`;

/**
 * Root URL for Portal Content
 *
 * @example
 * ```
 * https://www.arcgis.com/sharing/rest/content
 * ```
 */
export const SHARING_CONTENT_URL = SHARING_HOST_URL + '/rest/content';

/**
 * Root URL for Community that contains operations related to users and groups
 *
 * @example
 * ```
 * https://www.arcgis.com/sharing/rest/community
 * ```
 */
export const SHARING_COMMUNITY_URL = SHARING_HOST_URL + '/rest/community';

/**
 * Root URL for an item in the Portal
 *
 * @example
 * ```
 * https://devext.arcgis.com/sharing/rest/content/items
 * ```
 */
export const PORTAL_ITEMS_ROOT_URL = SHARING_CONTENT_URL + '/items';

/**
 * URL to search content from ArcGIS Online
 *
 * @example
 * ```
 * https://www.arcgis.com/sharing/rest/search
 * ```
 */
export const PORTAL_CONTENT_SEARCH_URL = `${SHARING_HOST_URL}/rest/search`;

/**
 * URL to get search suggest from ArcGIS Online
 *
 * @example
 * https://www.arcgis.com/sharing/rest/search/suggest
 */
export const PORTAL_CONTENT_SUGGEST = `${PORTAL_CONTENT_SEARCH_URL}/suggest`;

/**
 * Root URL for portal users
 *
 * @example
 * ```
 * https://www.arcgis.com/sharing/rest/community/users
 * ```
 */
export const PORTAL_USERS_URL = `${SHARING_COMMUNITY_URL}/users`;

/**
 * URL to get Category Schema JSON file for LAW Master GROUP
 *
 * @example
 * "https://devext.arcgis.com/sharing/rest/content/items/2e2012d735b540d7bd99b31272b3d815/data"
 */
export const LAW_MASTER_GROUP_CATEGORY_SCHEMA_DATA_URL = `${PORTAL_ITEMS_ROOT_URL}/${MASTER_GROUP_SCHEMA_ID}/data`;

/**
 * ArcGIS REST API request URL used to access the group content search operation
 *
 * @example
 * "https://devext.arcgis.com/sharing/rest/content/groups/6a227743f5404850803716f4bb79e787/search"
 */
export const LIVING_ATLAS_MASTER_GROUP_CONTENT_SEARCH = `${SHARING_CONTENT_URL}/groups/${LIVING_ATLAS_MASTER_GROUP_ID}/search`;

/**
 * Content Nomination Table used to store User Contributions to the Living Atlas.
 */
export const CONTENT_NOMINATION_TABLE = isPROD
    ? PROD_CONTENT_NOMINATION_TABLE
    : DEV_CONTENT_NOMINATION_TABLE;

/**
 * Home page to edit user profile
 */
export const USER_PROFILE_HOME = `${PORTAL_URL}/home/user.html`;

/**
 * Root URL for Storymaps
 *
 * @example
 * https://storymaps.arcgis.com
 */
export const STORYMAPS_ROOT = isPROD ? PROD_STORYMAPS_ROOT : DEV_STORYMAPS_ROOT;

/**
 * Root URL for Web Experiences
 *
 * @example
 * https://experience.arcgis.com
 */
export const EXPERIENCE_ROOT = isPROD
    ? PROD_EXPERIENCE_ROOT
    : DEV_EXPERIENCE_ROOT;
