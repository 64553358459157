import './style.css';
import globalNav from './lib/esri-global-nav';
import placeHolderUserThumbnail from './img/placeholder-user-thumb.jpg';

import getMenuData from './MenuData';
import {
    getPortalBaseUrl,
    getUserPortal,
    isAnonymouns,
    signIn,
    signOut,
    switchAccount,
} from '@shared/utils/esri-oauth';

type UserData = {
    /**
     * full name of user
     */
    name: string;
    /**
     * username
     */
    id: string;
    /**
     * ArcGIS Online org name of signed in user
     */
    group: string;
    /**
     * avatar thumbnail image URL
     */
    image: string;
};

const HEADER_CONTAINER_CLASSNAME = 'esri-header-barrier';
const FOOTER_CONTAINER_CLASSNAME = 'esri-footer-barrier';

const esriHeader = document.querySelector(`.${HEADER_CONTAINER_CLASSNAME}`);
const esriFooter = document.querySelector(`.${FOOTER_CONTAINER_CLASSNAME}`);

/**
 * Get URL of Profile and Settings page on ArcGIS Online
 * @returns
 */
const getProfileSettingsURL = () => {
    if (isAnonymouns()) {
        return null;
    }

    const portalBaseUrl = getPortalBaseUrl();

    return `${portalBaseUrl}/home/user.html`;
};

/**
 * get the user data object to populate Esri Global Nav
 * @returns UserData
 */
const getUserData = (): UserData => {
    const userPortal = getUserPortal();

    if (!userPortal) {
        return null;
    }

    const { name, user } = userPortal;

    const { fullName, username, thumbnailUrl } = user;

    return {
        // 	name: 'Cassidy Bishop',
        name: fullName,
        // 	id: 'iamoktatester@gmail.com',
        id: username,
        // 	group: 'Riverside City Mgmt.',
        group: name,
        // 	image: '//placehold.it/300x300'
        image: thumbnailUrl || placeHolderUserThumbnail,
    };
};

/**
 * Populate Esri Global Nav that includes Esri Header and Esri Footer componenets
 */
export const initEsriGlobalNav = (signInStatusIsChecked: boolean) => {
    // remove existing content inside of esri header and footer
    // the esri global nav will get populated before esri oauth sign in status get returned,
    // but need to be repopulated once sign-in status is checked to reflect the user data of signed in user
    esriHeader.innerHTML = '';
    esriFooter.innerHTML = '';

    const menuData = getMenuData();

    menuData.header.account.user = getUserData() || null;

    const profileUrl = getProfileSettingsURL();

    if (profileUrl) {
        menuData.header.account.menus[0].href = profileUrl;
    }

    globalNav.create({
        headerElm: `.${HEADER_CONTAINER_CLASSNAME}`,
        footerElm: `.${FOOTER_CONTAINER_CLASSNAME}`,
        menuData,
    });

    // the account section will only be visible when sign-in status is checked
    if (signInStatusIsChecked) {
        esriHeader.addEventListener('header:click:signin', () => {
            signIn();
        });

        esriHeader.addEventListener('header:click:signout', () => {
            signOut();
        });

        esriHeader.addEventListener('header:click:switch', () => {
            switchAccount();
        });

        // open OneTrust Modal when clicks on "Manage Cookies" button
        document
            .querySelector('#esri-footer-info-link--1 a.esri-footer-info-link')
            .addEventListener('click', () => {
                OneTrust?.ToggleInfoDisplay();
            });
    } else {
        // we don't know if current user is signed in or not yet as it is still in process of checking sign-in status,
        // that's why we should hide account section to prevent user from clicking the sign in button
        [
            document.querySelector('.esri-header-client'),
            document.querySelector('.esri-header-search'),
        ].forEach((elem) => {
            elem.classList.add('hidden');
        });

        document.querySelectorAll('.esri-header-lineBreak').forEach((elem) => {
            elem.classList.add('hidden');
        });
    }
};
