import './JoinConversation.css';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ARCGIS_BLOG_LIVING_ATLAS_URL,
    GEONET_LIVING_ATLAS_URL,
} from '@shared/constants/urls';
import classNames from 'classnames';

type JoinConversationBlockProps = {
    title: string;
    descripton: string;
    buttonLabel: string;
    buttonURL: string;
    showRightBorder?: boolean;
};

const JoinConversationBlock: FC<JoinConversationBlockProps> = ({
    title,
    descripton,
    buttonLabel,
    buttonURL,
    showRightBorder = false,
}: JoinConversationBlockProps) => {
    return (
        <div
            className={classNames('text-center max-w-lg px-12 my-4', {
                'md:border-r border-white border-opacity-30': showRightBorder,
            })}
        >
            <h5 className="font-size-3 font-light mb-3">{title}</h5>
            <p className="mb-6">{descripton}</p>
            <calcite-button kind="neutral" href={buttonURL}>
                <span>{buttonLabel}</span>
            </calcite-button>
        </div>
    );
};

const JoinConversation = () => {
    const { t } = useTranslation();

    return (
        <div className="relative bg-join-conversation">
            <div className="background-tint"></div>

            <div className="relative container flex flex-col md:flex-row justify-center items-center py-20 z-10 text-white">
                <JoinConversationBlock
                    title={t('join_conversation_title', { ns: 'common' })}
                    descripton={t('join_conversation_descripton', {
                        ns: 'common',
                    })}
                    buttonLabel={t('go_to_geonet', { ns: 'common' })}
                    buttonURL={GEONET_LIVING_ATLAS_URL}
                    showRightBorder={true}
                />

                <JoinConversationBlock
                    title={t('read_blog_title', { ns: 'common' })}
                    descripton={t('read_blog_descripton', { ns: 'common' })}
                    buttonLabel={t('go_to_blog', { ns: 'common' })}
                    buttonURL={ARCGIS_BLOG_LIVING_ATLAS_URL}
                />
            </div>
        </div>
    );
};

export default JoinConversation;
