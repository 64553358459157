import SiteConfig from '../../../../site.config.json';

const { LANGUAGES, PAGES } = SiteConfig;

type Page = {
    /**
     * Language of the current page.
     * To find all languages supported by the Living Atlas website:
     *
     * check `LANGUAGES` in `site.config.json`
     */
    language: string;
    /**
     * name of the current page
     * (e.g. 'home', 'browse', 'apps', 'contributions' and etc)
     *
     * To find all pages included in Living Atlas website :
     *
     * check `PAGES` in `site.config.json`
     */
    page: string;
};

/**
 * Parse URL's path to get the name and language of the current page.
 *
 *
 *
 * @example
 * Usage
 * ```js
 * // URL of the current page is "https://localhost:8080/en/apps/"
 * parseUrlPathname()
 * ```
 *
 * Returns
 * ```js
 * {
 *   page: "apps",
 *   language: "en"
 * }
 * ```
 */
export const parseUrlPathname = (): Page => {
    const paths = window.location.pathname.split('/');

    // remove the empty string from the end of paths array
    // this can happen when the input path is `/en/contributions/`
    while (paths.length && !paths[paths.length - 1]) {
        paths.pop();
    }

    let page = paths.pop() || '';
    let language = paths.pop() || '';

    // TO-DO: remove this line below as it is just for testing the refactored code
    page = page.replace('-refactor', '');

    // if the page name is invalid, use 'home' to direct user to the home page
    if (PAGES.includes(page) === false) {
        page = 'home';
    }

    // if language is invalid, use 'en' to set English as the language
    if (LANGUAGES.includes(language) === false) {
        language = 'en';
    }

    return {
        page,
        language,
    };
};
