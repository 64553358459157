import { parseUrlPathname } from '../url/path';

const { page } = parseUrlPathname();

/**
 * redirect to browse page with `q` in search param
 * @param q
 * @returns
 */
export const redirect2BrowsePage = (q: string) => {
    if (!q) {
        return;
    }

    // already at browse page, no need to redirect
    if (page === 'browse') {
        return;
    }

    window.location.href = `../browse/?q=${q}#q=${q}`;
};
